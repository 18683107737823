exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-about-tsx": () => import("./../../../src/templates/About.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/Contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-default-page-tsx": () => import("./../../../src/templates/DefaultPage.tsx" /* webpackChunkName: "component---src-templates-default-page-tsx" */),
  "component---src-templates-hire-page-tsx": () => import("./../../../src/templates/HirePage.tsx" /* webpackChunkName: "component---src-templates-hire-page-tsx" */),
  "component---src-templates-iframe-page-tsx": () => import("./../../../src/templates/IframePage.tsx" /* webpackChunkName: "component---src-templates-iframe-page-tsx" */),
  "component---src-templates-plain-text-page-tsx": () => import("./../../../src/templates/PlainTextPage.tsx" /* webpackChunkName: "component---src-templates-plain-text-page-tsx" */),
  "component---src-templates-quote-tsx": () => import("./../../../src/templates/Quote.tsx" /* webpackChunkName: "component---src-templates-quote-tsx" */),
  "component---src-templates-welfare-page-tsx": () => import("./../../../src/templates/WelfarePage.tsx" /* webpackChunkName: "component---src-templates-welfare-page-tsx" */)
}

