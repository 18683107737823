exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
	if (location.hash === '') {
		window.scrollTo(0, 0)
		const body = document.getElementsByTagName('body')[0]
		body.scrollTop = 0
	}

	return false
}